(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/match-info-hooks/assets/javascripts/use-bet-event.js') >= 0) return;  svs.modules.push('/components/sportinfo/match-info-hooks/assets/javascripts/use-bet-event.js');
"use strict";


const {
  useSelector
} = ReactRedux;
const {
  selectEventStatistic,
  selectBetEvent
} = svs.components.sportinfo.matchInfoRedux.selectors;
const {
  createEventStatisticsKey,
  createBetEventKey
} = svs.components.sportinfo.common.helpers;
const defaultBetEvent = {
  matchId: undefined,
  eventTypeId: undefined,
  participantIds: undefined
};

const useBetEvent = _ref => {
  let {
    productId,
    drawNumber,
    eventNumber
  } = _ref;
  const betEventKey = createBetEventKey(productId, drawNumber, eventNumber);
  const betEvent = useSelector(state => selectBetEvent(state, betEventKey)) || defaultBetEvent;
  const {
    matchId,
    eventTypeId,
    participants: participantIds,
    periodNumber
  } = betEvent;
  const eventStatisticsKey = createEventStatisticsKey({
    matchId,
    eventTypeId,
    participantIds,
    periodNumber
  });
  const eventStatistics = useSelector(state => selectEventStatistic(state, eventStatisticsKey));
  return {
    betEvent,
    betEventKey,
    eventStatistics,
    eventStatisticsKey
  };
};
setGlobal('svs.components.sportinfo.matchInfoHooks.useBetEvent', useBetEvent);

 })(window);